import(/* webpackMode: "eager" */ "/vercel/path0/app/[country]/(country)/page.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/ErrorCountry/ErrorCountry.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/GetTheAppDialog/GetTheAppDialog.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/layout/Footer/Footer.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/layout/Navbar/Navbar.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/pages/Homepage/Homepage.jsx");
